import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { message } from 'antd';

const Alert = ({ alerts }) => {
  const [messageApi, contextHolder] = message.useMessage();
  const [prevAlertsLength, setPrevAlertsLength] = React.useState(0);

  useEffect(() => {
    if (alerts.length > prevAlertsLength) {
      const alert = alerts[alerts.length - 1];
      messageApi.open({
        type: alert.alertType === 'success' ? 'success' : 'error',
        content: alert.msg,
      });
    }
    // 更新上一次的警报数量
    setPrevAlertsLength(alerts.length);
  }, [alerts, messageApi, prevAlertsLength]);
  return (
    <>
      {contextHolder}
      {/* <div className="alert-wrapper">
        {alerts.map((alert) => (
          <div key={alert.id} className={`alert alert-${alert.alertType}`}>
            {alert.msg}
          </div>
        ))}
      </div> */}
    </>
  );
};

Alert.propTypes = {
  alerts: PropTypes.array.isRequired,
};

const mapStateToProps = (state) => ({
  alerts: state.alert,
});

export default connect(mapStateToProps)(Alert);
