// MobileNavbar.js
import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import styled, { keyframes } from 'styled-components';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faHome,
  faUserFriends,
  faEnvelope,
  faBell,
  faUser,
} from '@fortawesome/free-solid-svg-icons';
// Styled components for the mobile navbar
const MobileNav = styled.nav`
  display: flex;
  justify-content: space-around;
  position: fixed;
  bottom: 0;
  width: 100%;
  background: #fff;
  box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.1);
  z-index: 10000;
  @media (max-width: 768px) {
    // Show only on mobile screens
    display: flex;
    justify-content: space-around;
    position: fixed;
    bottom: 0;
    width: 100%;
    background: #fff;
    box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.1);
    z-index: 10000;
  }
`;

const ripple = keyframes`
  0% {
    transform: scale(0);
    opacity: 1;
  }
  20% {
    transform: scale(3);
    opacity: 0.375;
  }
  100% {
    opacity: 0;
    transform: scale(0);
  }
`;

const NavItem = styled(Link)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 10px;
  color: #999;
  position: relative;
  &.active {
    color: #333; // Active color
    // Add this before pseudo-element for the ripple effect
    &::before {
      content: '';
      position: absolute;
      left: 30%;
      top: 30%;
      width: 20px;
      height: 20px;
      background-color: #e91e63; // Ripple color
      border-radius: 50%;
      animation: ${ripple} 0.4s ease-in-out forwards;
      z-index: 0;
    }
  }
  & svg {
    margin-bottom: 5px;
    position: relative;
    z-index: 1;
  }
`;

const NavLabel = styled.span`
  font-size: 0.75rem;
`;
// Style for the notification bubble
const NotificationBubble = styled.span`
  position: absolute;
  top: 2px; // Adjust this value as needed
  right: 0px; // Adjust this value as needed
  background-color: red;
  color: white;
  border-radius: 50%;
  padding: 5px;
  font-size: 0.75rem;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 5px; // Ensure it's round even if there's only one digit
  height: 5px; // Make it a perfect circle
`;

// Create a function to determine if the path is active
const isActivePath = (path, location) => {
  if (!typeof path === 'string') {
    return false;
  }
  return location.pathname === path;
};

const MobileNavbar = ({
  auth: { user, isAuthenticated },
  logout,
  notification: { totalUnreads },
  friend: { friendsRequest },
  message: { messages, unreadCounts, messageUserpairs },
  event: { events, passiveEvents },
}) => {
  const location = useLocation();

  const isWaitingForFeedback =
    events.filter((event) => event.isWaitingForResponse === true).length > 0 ||
    passiveEvents.filter((event) => event.isWaitingForResponse === true).length > 0;

  let totalUnreadNotifications = totalUnreads;
  let isMessagePage = location.pathname.includes('/message');
  let hasUnreadMessages = false;
  if (isAuthenticated) {
    for (let key in unreadCounts) {
      if (unreadCounts[key] > 0) {
        hasUnreadMessages = true;
        break;
      }
    }
  }
  if (isMessagePage) {
    return null;
  }
  return (
    <>
      {isAuthenticated && (
        <MobileNav>
          <NavItem
            to={isAuthenticated ? `/?userId=${user?._id}` : '/login'}
            className={
              isActivePath('/', location) || isActivePath('/post', location) ? 'active' : ''
            }
          >
            <FontAwesomeIcon icon={faHome} size="lg" />
            <NavLabel>首页</NavLabel>
          </NavItem>
          <NavItem
            to={
              !isAuthenticated
                ? '/login'
                : friendsRequest.length > 0
                ? `/pet-profiles#right?userId=${user?._id}`
                : `/pet-profiles#left?userId=${user?._id}`
            }
            className={
              isActivePath('/pet-profiles', location) || isActivePath('/friend-requests', location)
                ? 'active'
                : ''
            }
          >
            <FontAwesomeIcon icon={faUserFriends} size="lg" />
            <NavLabel>崽崽</NavLabel>
            {isAuthenticated && (friendsRequest.length > 0 || hasUnreadMessages) && (
              <NotificationBubble></NotificationBubble>
            )}
          </NavItem>
          <NavItem
            to={isAuthenticated ? `/stories?userId=${user?._id}` : '/login'}
            className={isActivePath('/stories', location) ? 'active' : ''}
          >
            <FontAwesomeIcon icon={faEnvelope} size="lg" />
            <NavLabel>故事</NavLabel>
            {isAuthenticated && isWaitingForFeedback && <NotificationBubble></NotificationBubble>}
          </NavItem>
          <NavItem
            to={isAuthenticated ? `/mailbox?userId=${user?._id}` : '/login'}
            className={isActivePath('/mailbox', location) ? 'active' : ''}
          >
            <FontAwesomeIcon icon={faBell} size="lg" />
            <NavLabel>通知</NavLabel>
            {isAuthenticated && totalUnreadNotifications > 0 && (
              <NotificationBubble></NotificationBubble>
            )}
          </NavItem>
          <NavItem
            to={isAuthenticated ? `/dashboard?userId=${user?._id}` : '/login'}
            className={isActivePath(`/dashboard`, location) ? 'active' : ''}
          >
            <FontAwesomeIcon icon={faUser} size="lg" />
            <NavLabel>我</NavLabel>
          </NavItem>
        </MobileNav>
      )}
    </>
  );
};

MobileNavbar.prototype = {
  auth: PropTypes.object.isRequired,
  notification: PropTypes.object.isRequired,
  friend: PropTypes.object.isRequired,
  event: PropTypes.object.isRequired,
  message: PropTypes.object.isRequired,
};
const mapStateToProps = (state) => ({
  auth: state.auth,
  notification: state.notification,
  friend: state.friend,
  event: state.event,
  message: state.message,
});

export default connect(mapStateToProps, {})(MobileNavbar);
