import React from 'react';
import { Navigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Spinner from '../layout/Spinner';

const AdminRoute = ({ component: Component, auth: { user, isAuthenticated, loading } }) => {
  if (loading)
    return (
      <>
        <section className="container">
          <Spinner />
        </section>
      </>
    );
  if (isAuthenticated && user?.role === 'admin') {
    return <Component />;
  }

  return <Navigate to="/dashboard" />;
};

AdminRoute.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, {})(AdminRoute);
