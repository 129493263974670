import React, { useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { getProfileById } from '../../actions/profile';
import { loadUser } from '../../actions/auth';
import store from '../../store';
import Spinner from '../layout/Spinner';
import setAuthToken from '../../utils/setAuthToken';

const AuthHandler = ({ isAuthenticated, profile: { profile, loading }, auth, getProfileById }) => {
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const query = new URLSearchParams(location.search);
    const token = query.get('token');
    if (token) {
      setAuthToken(token);
      store.dispatch(loadUser());
    }
  }, [location]);

  useEffect(() => {
    const query = new URLSearchParams(location.search);
    const redirect = query.get('redirect');
    if (redirect) {
      navigate(redirect);
      return;
    }
    if (isAuthenticated) {
      navigate('/');
    }
  }, [location, navigate, isAuthenticated]);

  return (
    <section className="container">
      <Spinner />
    </section>
  );
};

AuthHandler.propTypes = {
  isAuthenticated: PropTypes.bool,
  auth: PropTypes.object.isRequired,
  profile: PropTypes.object.isRequired,
  getProfileById: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
  auth: state.auth,
  profile: state.profile,
});

export default connect(mapStateToProps, { getProfileById })(AuthHandler);
