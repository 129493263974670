import { useState, useEffect, useRef, useCallback } from 'react';

export function useDebounceHook(value, delay) {
  const [debounceValue, setDebounceValue] = useState(value);
  useEffect(() => {
    let timer = setTimeout(() => setDebounceValue(value), delay);
    return () => clearTimeout(timer);
  }, [value, delay]);
  return debounceValue;
}

export function useThrottleValue(value, duration) {
  const [throttleValue, setThrottleValue] = useState(value);
  let Local = useRef({ flag: true }).current;
  useEffect(() => {
    let timer;
    if (Local.flag) {
      Local.flag = false;
      setThrottleValue(value);
      setTimeout(() => (Local.flag = true), duration);
    } else {
      timer = setTimeout(() => setThrottleValue(value), duration);
    }
    return () => clearTimeout(timer);
  }, [value, duration, Local]);
  return throttleValue;
}

// Custom hook for throttling a function call
export function useThrottle(callback, delay) {
  const lastCallRef = useRef(0);

  const throttledFunction = useCallback(
    (...args) => {
      const now = Date.now();
      if (now - lastCallRef.current > delay) {
        lastCallRef.current = now;
        callback(...args);
      }
    },
    [callback, delay],
  );

  return throttledFunction;
}

export function debounceFunc(func, delay) {
  let inDebounce;
  return function () {
    const context = this;
    const args = arguments;
    clearTimeout(inDebounce);
    inDebounce = setTimeout(() => func.apply(context, args), delay);
  };
}
