import React, { useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import styles from './auth.module.css'; // Ensure this path is correct
import { Link, Navigate, useNavigate } from 'react-router-dom';
import { sendEmailToRegister } from '../../../actions/auth';
import icon from '../../../img/CuteIcon.png';
import googleIcon from '../../../img/google_g_icon.png';

const EmailSubmission = ({ sendEmailToRegister, isAuthenticated }) => {
  const navigate = useNavigate();
  const [email, setEmail] = useState('');

  const [isValid, setIsValid] = useState(false);

  const validateEmail = (email) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    setIsValid(re.test(email));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)) {
      return;
    }
    const emailSendResponse = await sendEmailToRegister(email);
    if (!emailSendResponse) {
      return;
    }
    sessionStorage.setItem('registrationStage', 'verify');
    setTimeout(() => {
      navigate(`/verify-email?email=${encodeURIComponent(email)}&step=verify`);
    }, 500);
  };

  if (isAuthenticated) {
    return <Navigate to="/dashboard" />;
  }

  return (
    <section className="container">
      <section className={styles.authContainer}>
        <img className="profiles-icon-medium " src={icon} alt=""></img>
        <h1 className={styles.largeText}>Register Your Email</h1>
        <form className={styles.form} onSubmit={handleSubmit}>
          <div className={styles.formGroup}>
            <input
              type="email"
              placeholder="Enter your email"
              name="email"
              value={email}
              onChange={(e) => {
                setEmail(e.target.value);
                validateEmail(e.target.value);
              }}
              className={styles.inputField}
              required
            />
            {/* {error && <p className={styles.errorMessage}>{error}</p>} */}
          </div>
          <div
            className={`${styles.errorMessage} ${
              email && email.length > 0 && !isValid ? styles.visible : ''
            }`}
          >
            Email not valid
          </div>
          <input
            type="submit"
            className={`${styles.loginButton} ${!isValid ? styles.disabledButton : ''}`}
            value="REGISTER"
            disabled={!isValid}
          />
        </form>
        <p className={styles.bottomText}>
          Already have an account?{' '}
          <Link to="/login" className={styles.linkText}>
            Sign In
          </Link>
        </p>
        <div className={styles.divider}>
          <div className={styles.dividerLine} />
          <span className={styles.dividerText}>OR</span>
          <div className={styles.dividerLine} />
        </div>
        <div className={styles.socialLogin}>
          <a
            href={`${process.env.REACT_APP_BACKEND_APIENDPOINT}/users/auth/google`}
            className={styles.googleLoginButton}
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={googleIcon} alt="Google" className={styles.googleIcon} />
            <span className={styles.googleText}>Sign up with Google</span>
          </a>
        </div>
      </section>
    </section>
  );
};
EmailSubmission.propTypes = {
  sendEmailToRegister: PropTypes.func.isRequired,
  isAuthenticated: PropTypes.bool,
};

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
});

export default connect(mapStateToProps, { sendEmailToRegister })(EmailSubmission);
