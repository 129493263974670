import React, { useState } from 'react';
import { Navigate } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import styled, { keyframes } from 'styled-components';
import image from './img/logo.png'; // Adjust the path as needed

const Landing = ({ isAuthenticated }) => {
  const [showAlert, setShowAlert] = useState(true);
  const [fadeOut, setFadeOut] = useState(false); // New state to track fade out animation

  if (isAuthenticated) {
    return <Navigate to="/dashboard" />;
  }

  const handleCloseAlert = () => {
    setFadeOut(true); // Trigger fade out animation
    setTimeout(() => setShowAlert(false), 500); // Remove alert after the fade out animation
  };

  return (
    <LandingSection>
      {showAlert && (
        <AlertBox fadeOut={fadeOut}>
          <span>
            The app is currently being upgraded and temporarily unavailable. Stay tuned for the
            exciting new version coming soon!
          </span>
          <CloseButton onClick={handleCloseAlert}>✕</CloseButton>
        </AlertBox>
      )}
      <ContentWrapper>
        <Logo src={image} alt="Zaisland logo" />
        <Subtitle>
          Explore an alternate life with your Zai and create enriching memories with other players
          on this vibrant, unbounded island.
        </Subtitle>
        <ButtonWrapper>
          <LoginButton>LOG IN</LoginButton>
          <SignupButton>SIGN UP</SignupButton>
        </ButtonWrapper>
      </ContentWrapper>
    </LandingSection>
  );
};

Landing.propTypes = {
  isAuthenticated: PropTypes.bool,
};

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
});

export default connect(mapStateToProps)(Landing);

// Keyframes for animations
const fadeIn = keyframes`
  0% { opacity: 0; transform: translateY(-20px); }
  100% { opacity: 1; transform: translateY(0); }
`;

const fadeOutAnimation = keyframes`
  0% { opacity: 1; transform: translateY(0); }
  100% { opacity: 0; transform: translateY(-20px); }
`;

// Styled Components
const LandingSection = styled.section`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #e4d5c0ff;
  flex-direction: column;
`;

const AlertBox = styled.div`
  position: absolute;
  top: 20px;
  left: 20px;
  right: 20px;
  padding: 15px;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: space-between;
  align-items: center;
  animation: ${({ fadeOut }) => (fadeOut ? fadeOutAnimation : fadeIn)} 0.5s ease-out;
`;

const CloseButton = styled.button`
  background: none;
  border: none;
  font-size: 18px;
  cursor: pointer;
  transition: transform 0.2s;
  &:hover {
    transform: scale(1.2);
  }
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
`;

const Logo = styled.img`
  width: 150px;
  margin-bottom: 20px;
`;

const Subtitle = styled.p`
  font-size: 16px;
  max-width: 300px;
  color: #666;
  margin-bottom: 40px;
`;

const ButtonWrapper = styled.div`
  display: flex;
  max-width: 200px;
  flex-direction: column;
  gap: 10px;
`;

const LoginButton = styled.button`
  background-color: #ff6b00;
  width: 200px;
  color: white;
  padding: 12px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s, transform 0.3s;

  &:hover {
    background-color: #e65c00;
    transform: translateY(-2px);
  }
`;

const SignupButton = styled.button`
  background-color: black;
  width: 100%;
  color: white;
  padding: 12px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s, transform 0.3s;

  &:hover {
    background-color: #333;
    transform: translateY(-2px);
  }
`;
