import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import OneSignalInitializer from './init/OneSignalInitializer';

const root = ReactDOM.createRoot(document.getElementById('root'));
/* prettier-ignore */
root.render(
  <>
    <App />
    <OneSignalInitializer />
  </>,
);
