import {
  GET_FOLLOWERS,
  GET_FOLLOWINGS,
  FOLLOW_ERROR,
  GET_MY_FOLLOWERS,
  GET_MY_FOLLOWINGS,
  UPDATE_FOLLOWERS,
  ADD_FOLLOWINGS,
  REMOVE_FOLLOWINGS,
} from '../actions/types';

const initialState = {
  myFollowers: [],
  myFollowings: [],
  followers: [],
  followings: [],
  myTotalFollowers: 0,
  myTotalFollowings: 0,
  error: {},
};

function followReducer(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case ADD_FOLLOWINGS: {
      let updatedFollowings = state.myFollowings.slice();
      let inFollowing = false;
      updatedFollowings.forEach((following) => {
        if (following.following._id === payload.following._id) {
          inFollowing = true;
        }
      });
      console.log('inFollowing', inFollowing);
      if (!inFollowing) {
        updatedFollowings.push(payload);
      }
      return {
        ...state,
        myTotalFollowings: state.myTotalFollowings + 1,
        loading: false,
        error: {},
      };
    }
    case REMOVE_FOLLOWINGS: {
      return {
        ...state,
        myFollowings: state.myFollowings.filter((following) => following.following !== payload),
        myTotalFollowings: state.myTotalFollowings - 1,
        error: {},
      };
    }
    case UPDATE_FOLLOWERS:
      return {
        ...state,
        myFollowers: state.myFollowers.filter((followers) => followers._id !== payload),
        myTotalFollowers: state.myTotalFollowers - 1,
        loading: false,
        error: {},
      };
    case GET_MY_FOLLOWERS: {
      return {
        ...state,
        myFollowers: payload.followers,
        myTotalFollowers: payload.totalFollowers,
        loading: false,
        error: {},
      };
    }
    case GET_MY_FOLLOWINGS:
      return {
        ...state,
        myFollowings: payload.followings,
        myTotalFollowings: payload.totalFollowings,
        error: {},
      };
    case FOLLOW_ERROR:
      return {
        ...state,
        error: payload,
        loading: false,
        cat: null,
      };

    case GET_FOLLOWERS:
      return {
        ...state,
        followers: payload.followers,
        totalFollowers: payload.totalFollowers,
        loading: false,
        error: {},
      };
    case GET_FOLLOWINGS:
      return {
        ...state,
        followings: payload.followings,
        totalFollowings: payload.totalFollowings,
        loading: false,
        error: {},
      };
    default:
      return state;
  }
}

export default followReducer;
