import { useEffect } from 'react';

const OneSignalInitializer = () => {
  useEffect(() => {
    const loadOneSignalSDK = () => {
      try {
        const script = document.createElement('script');
        script.src = 'https://cdn.onesignal.com/sdks/web/v16/OneSignalSDK.page.js';
        script.async = true;
        script.defer = true;
        script.onload = () => {
          window.OneSignalDeferred = window.OneSignalDeferred || [];
          window.OneSignalDeferred.push(function (OneSignal) {
            OneSignal.init({
              appId: process.env.REACT_APP_ONESIGNAL_APP_ID,
              autoResubscribe: false,
            });
          });
        };
        script.onerror = () => {
          console.error('Failed to load OneSignal SDK');
        };
        document.head.appendChild(script);
      } catch (error) {
        console.error('Failed to load OneSignal SDK:', error);
      }
    };

    loadOneSignalSDK();
  }, []);

  return null;
};

export default OneSignalInitializer;
