import React from 'react';
import { Link } from 'react-router-dom';
import styles from './TermsAndConditions.module.css'; // Make sure to create this CSS module

const TermsAndConditions = () => {
  return (
    <section className="container">
      <div className={styles.termsContainer}>
        <h1>Terms and Conditions</h1>
        <p>Please read these terms and conditions carefully before using Our Service.</p>

        <h2>Interpretation and Definitions</h2>
        <h3>Interpretation</h3>
        <p>
          The words of which the initial letter is capitalized have meanings defined under the
          following conditions. The following definitions shall have the same meaning regardless of
          whether they appear in singular or in plural.
        </p>

        <h3>Definitions</h3>
        <p>For the purposes of these Terms and Conditions:</p>
        <ul>
          <li>
            <strong>Company</strong> (referred to as either "the Company", "We", "Us" or "Our" in
            this Agreement) refers to Your Company Name.
          </li>
          <li>
            <strong>Service</strong> refers to the Website.
          </li>
          <li>
            <strong>Terms and Conditions</strong> (also referred as "Terms") mean these Terms and
            Conditions that form the entire agreement between You and the Company regarding the use
            of the Service.
          </li>
          <li>
            <strong>Website</strong> refers to Your Website, accessible from YourWebsite.com
          </li>
          <li>
            <strong>You</strong> means the individual accessing or using the Service, or the
            company, or other legal entity on behalf of which such individual is accessing or using
            the Service, as applicable.
          </li>
        </ul>

        <h2>Acknowledgment</h2>
        <p>
          These are the Terms and Conditions governing the use of this Service and the agreement
          that operates between You and the Company. These Terms and Conditions set out the rights
          and obligations of all users regarding the use of the Service.
        </p>

        <h2>Links to Other Websites</h2>
        <p>
          Our Service may contain links to third-party web sites or services that are not owned or
          controlled by the Company.
        </p>
        <p>
          The Company assumes no responsibility for the content, privacy policies, or practices of
          any third-party web sites or services. You further acknowledge and agree that the Company
          shall not be responsible or liable, directly or indirectly, for any damage or loss caused
          or alleged to be caused by or in connection with the use of or reliance on any such
          content, goods or services available on or through any such web sites or services.
        </p>

        <h2>Changes to These Terms and Conditions</h2>
        <p>
          We reserve the right, at Our sole discretion, to modify or replace these Terms at any
          time. If a revision is material We will make reasonable efforts to provide at least 30
          days' notice prior to any new terms taking effect. What constitutes a material change will
          be determined at Our sole discretion.
        </p>

        <h2>Contact Us</h2>
        <p>If you have any questions about these Terms and Conditions, You can contact us:</p>
        <ul>
          <li>By email: support@katfun.com</li>
          {/* <li>By visiting this page on our website: yourwebsite.com/contact</li> */}
        </ul>

        <Link to="/register">Return to Sign In</Link>
      </div>
    </section>
  );
};

export default TermsAndConditions;
